<template>
  <b-card>
    <header-table title="تصنيفات فرص العمل" @create="onAdd" />
    <data-table ref="estatesTableee" :fields="fields" ep="/opportunity-types">
      <template #cell(actions)="row">
        <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)" />
      </template>
      <template #cell(icon)="row">
        <b-img style="width: 20px;" :src="row.item.icon" />
      </template>
    </data-table>
    <form-modal ref="estateModalAboutContent" :formSchema="estatesFormSchema" title="إضافة " v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
      <template #icon="{ form }">
        <form-file :rules="form.id ? '' : 'required'" label="صورة" accept="image/*" v-model="icon" id="form-file-icon"
          name="icon" placeholder="لم تقم باختيار صور" @change="previewImage" />
        <div class="text-center mb-1">
          <layout-input-image v-if="staticImages.url" :url="staticImages.url" :name="''" :is_last_imge="false"
            @deleteItem="deleteImage()">
          </layout-input-image>
          <layout-input-image v-if="!staticImages.url && form.icon" :url="form.icon" :name="''" :is_last_imge="false"
            :is_delete="false">
          </layout-input-image>
        </div>
      </template>
    </form-modal>
  </b-card>
</template>
<script>
import { BCard, BImg } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/data-table/index.vue"
import FormModal from "@/components/FormModal.vue"
import HeaderTable from "@/components/data-table/components/header-table.vue"
import ActionsTable from "@/components/data-table/components/actions-table.vue"
import FormFile from "@/components/form-file/index.vue"
import dataSchema from "../../mixin"
import LayoutInputImage from "@/components/LayoutInputImage.vue";

export default {
  components: { BImg, BCard, DataTable, FormModal, HeaderTable, ActionsTable, FormFile, LayoutInputImage },
  data() {
    return {
      activModel: false,
      icon: [],
      staticImages: { url: '' }
    };
  },
  mixins: [dataSchema],
  methods: {
    ...mapActions("settings/selects", ["update", "delete", "create"]),
    previewImage(e) {
      this.staticImages = {
        url: URL.createObjectURL(e.target.files[0]),
      }
    },
    deleteImage() {
      this.staticImages.url = ''
      this.icon = null
    },
    onAdd() {
      this.$refs.estateModalAboutContent.init({});
      this.deleteImage()
      this.activModel = true;
    },
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف", {
          title: "تأكيد حذف",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({ ep: `/opportunity-types/${item.id}` }).then(() => {
              this.$refs.estatesTableee.refreshTable();
            });
        });
    },
    onUpdateClicked(form) {
      this.deleteImage()
      this.$refs.estateModalAboutContent.init(form);
      this.activModel = true;
    },
    onModalConfirmed(form) {
      var fd = new FormData();
      fd.append('name', form.name)
      if (this.icon) {
        fd.append('icon', this.icon)
      }

      if (form.id) {
        fd.append('_method', 'PUT')
        this.update({ data: fd, ep: `/opportunity-types/${form.id}` }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
      } else
        this.create({ data: fd, ep: `/opportunity-types` }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
    },
  },
  computed: {
    ...mapGetters("settings/selects", ["loading"]),
  },
};
</script>